<template>
  <div>
    <div id="fb-root"></div>
    <targetComponent></targetComponent>
    <OrganismsDialogsMessage :show="message.show" :icon="message.icon" :text="message.text" :button-text="message.buttonText" :type="message.type" @hide="hideMessage" />
    <OrganismsDialogsLoader />
    <OrganismsDialogsAddedToCart />
    <Transition>
      <OrganismsDialogsCookieConsent v-if="cookieConsentVisible" @consentGranted="cookieConsentGranted()" />
    </Transition>
  </div>
</template>

<script setup>
const appConfig = useAppConfig();
const pageData = usePage().value.page;
const { locale } = useI18n();

//useI18n().setLocale()

let moduleName = appConfig.moduleMapping[pageData?.module.id];

if (!moduleName) {
  moduleName = '404';
}

let targetComponent = defineAsyncComponent(
  () => import(`../components/pages/${moduleName}.vue`)
);

useHead({
  title: `${pageData?.title} | SnubníPrsteny4u.eu`,
  meta: [
    { name: 'keywords', content: pageData?.keywords },
    { name: 'description', content: pageData?.description }
  ],
  link: [
    // { rel: 'canonical', href: `${useRuntimeConfig().SHOP_ZONE_AUTHORITY}/${route.path.substring(1)}${route.query.p ? '?p=' + route.query.p : ''}` }
  ],
  script: [
    { src: 'https://connect.facebook.net/cs_CZ/sdk.js#xfbml=1&version=v18.0&appId=136803901204260&autoLogAppEvents=1', async: true, defer: true, crossorigin: 'anonymous', nonce: 'JM6sJgEI' }
  ]
});

// hide carousel pagination last items
onMounted(() => {
  setTimeout(() => {
    document.querySelectorAll('.carousel__pagination').forEach(pagination => {
      const numberOfSlides = pagination.parentElement.querySelectorAll('.carousel__slide').length;

      pagination.querySelectorAll('.carousel__pagination-item').forEach((paginationItem, index) => {
        // 2 slides shown - hide 1 last item
        if (window.matchMedia('(min-width: 1280px)').matches) {
          if ((index + 1) >= numberOfSlides - 1) {
            paginationItem.classList.add('hidden');
          }
        }
        // 3 slides shown - hide 2 last items
        else if (window.matchMedia('(min-width: 600px)').matches) {
          if ((index + 1) >= numberOfSlides) {
            paginationItem.classList.add('hidden');
          }
        }
      });
    });
  }, 200);
});

const cookieConsentVisible = ref(useShopCookie().read('cookieConsent') == null);

const cookieConsentGranted = () => {
  cookieConsentVisible.value = false;
};

const message = useMessage().message;
const hideMessage = () => useMessage().hide();

</script>
<style lang="postcss">
* {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  @apply font-primary;
}

input:focus {
  @apply outline-0;
}

.page-container {
  @apply container mx-auto px-4 lg:px-6;
}

/* button - global class usable from htmlContent of infopages */

.button {
  @apply border-2 border-primary inline-flex items-center justify-between px-4 py-3 lg:px-8 lg:py-4 rounded-md uppercase font-bold !no-underline !text-white !bg-primary hover:!bg-black cursor-pointer;
  transition: background-color ease-in-out 0.15s;
}

.button--block {
  @apply flex justify-center;
}

.button--small {
  @apply px-2;
}

.button--xsmall {
  @apply px-2 py-2;
}

.button--ghost {
  @apply !text-primary font-normal normal-case !bg-white hover:!bg-silver;
}

.button--conversion {
  @apply !border-conversion !bg-conversion hover:!bg-conversion;
}

.button--ghost.button--conversion {
  @apply !text-conversion hover:!text-white !bg-white hover:!bg-conversion;
}

.button--secondary {
  @apply !text-primary !bg-secondary !border-secondary;

  &:hover {
    @apply !text-white !bg-conversion !border-conversion;
  }
}

.badge {
  &--5992111 {
    @apply bg-gray;
  }
}

.margin-top-5 {
  @apply mt-1;
}

.margin-top-10 {
  @apply mt-2;
}

.margin-top-15 {
  @apply mt-4;
}

.margin-top-20 {
  @apply mt-5;
}

.margin-top-25 {
  @apply mt-6;
}

.margin-top-30 {
  @apply mt-8;
}

.margin-top-40 {
  @apply mt-10;
}

.margin-top-50 {
  @apply mt-12;
}

.margin-bottom-5 {
  @apply mb-1;
}

.margin-bottom-10 {
  @apply mb-2;
}

.margin-bottom-15 {
  @apply mb-4;
}

.margin-bottom-20 {
  @apply mb-5;
}

.margin-bottom-25 {
  @apply mb-6;
}

.margin-bottom-30 {
  @apply mb-8;
}

.margin-bottom-40 {
  @apply mb-10;
}

.margin-bottom-50 {
  @apply mb-12;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>